import React, { useEffect, useRef } from "react";
import { Box } from "src/elements";
import { PlanogramBay, PlanogramMeta, Planogram as PlanogramType } from "src/types";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { Bay } from "./components/Bay";
import { Shelf } from "./components/Shelf/Shelf";
import { BayPanel } from "./panels/BayPanel";
import { NewBay } from "./panels/components/NewBay";
import { ItemPanel } from "./panels/ItemPanel";
import { ShelfPanel } from "./panels/ShelfPanel";
import { baysEditModeAtom } from "./store/atoms";
import { PlanogramVariant } from "./types";
import { onPlanogramMouseMove } from "./utils";
import { PLANOGRAM_CONTAINER } from "./utils/classes";

const PlanogramContainer = styled.div`
	height: 100%;
	align-items: flex-end;
	margin-bottom: 10px;
	transform: scale(var(--bays-zoom));
	transform-origin: top left;
`;

interface Props {
	data: PlanogramType;
	meta: PlanogramMeta;
}

export const Planogram: React.FC<Props> = ({ data: planogram, meta }) => {
	const editMode = useOmniValue(baysEditModeAtom);

	const refPlanogram = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.body.addEventListener("mousemove", onPlanogramMouseMove as any);

		return function cleanup() {
			document.body.removeEventListener("mousemove", onPlanogramMouseMove as any);
		};
	}, []);

	/**
	 * Update planogram scale based on its content.
	 */
	useEffect(() => {
		if (planogram) updateScaleRatio(planogram);
	}, [planogram]);

	const updateScaleRatio = (planogram: PlanogramType) => {
		if (!refPlanogram.current) return;

		const planogramHeight = refPlanogram.current.parentElement!.offsetHeight - 60;

		let highestBayHeight = 0;
		planogram.bays.forEach(bay => {
			highestBayHeight = Math.max(highestBayHeight, bay.bay_height);
		});

		const scale = Math.floor((planogramHeight / highestBayHeight) * 100) / 100;
		document.body.style.setProperty("--scale", String(scale));
	};

	const getBayWidth = (bay: PlanogramBay) => {
		let bayWidth = 0;

		bay.shelves.forEach(shelf => {
			if (shelf.shelf_width > bayWidth) {
				bayWidth = shelf.shelf_width;
			}
		});

		return bayWidth;
	};

	return (
		<>
			<Box gap="20px" minHeight="100%">
				<PlanogramContainer ref={refPlanogram} className={PLANOGRAM_CONTAINER}>
					{planogram?.bays.map((bay, bayIndex) => (
						<Bay key={bayIndex} bay={bay} type="planogram" width={getBayWidth(bay)}>
							{bay.shelves.map((shelf, shelfIndex) => (
								<Shelf
									key={shelfIndex}
									bayNo={bay.bay_no}
									shelves={[shelf]}
									variant={PlanogramVariant.PLANOGRAM}
									blocks={
										meta.generated_mudmap
											? meta.generated_mudmap.bays[bayIndex].shelves[shelfIndex].blocks
											: []
									}
								/>
							))}
						</Bay>
					))}
				</PlanogramContainer>

				{editMode && <NewBay size="big" position="static" />}
			</Box>

			<BayPanel />
			<ShelfPanel />
			<ItemPanel />
		</>
	);
};
