import hexToRgba from "hex-to-rgba";
// @ts-ignore
import { LightenDarkenColor } from "lighten-darken-color";
import { getMudmapCdtColorByIndex } from "src/constants/mudmapCdtColors";
import { Text } from "src/elements";
import { baysColorsByAtom } from "src/pages/Bays/store/atoms";
import { Color } from "src/utils";
import { useOmniValue } from "src/utils/atoms";
import styled from "styled-components";

import { baysColorsAtom } from "../store/atoms";
import { VisualizerColorBy } from "../types";

const BlockItemElement = styled.div<{ background: string }>`
	background-color: ${({ background }) => background};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex: 1;
	align-self: stretch;
	overflow: hidden;
	box-shadow: ${({ background }) =>
		`inset 0 0 0 1px ${hexToRgba(LightenDarkenColor(background, -30), 0.4)}`};
	border-radius: 2px;
`;

interface Props {
	segment: string;
	block: string;
}

export const BlockItem = ({ segment, block }: Props) => {
	const colorsBy = useOmniValue(baysColorsByAtom);
	const colors = useOmniValue(baysColorsAtom);

	const itemId =
		(colorsBy === VisualizerColorBy.BLOCK && block) ||
		(colorsBy === VisualizerColorBy.SEGMENT && segment) ||
		`${segment} - ${block}`;

	const getColor = (colorsBy: VisualizerColorBy) => {
		if (colors && colors.segment && colorsBy === VisualizerColorBy.SEGMENT) {
			return colors.segment[`${segment}`];
		}

		if (colors && colors.block && colorsBy === VisualizerColorBy.BLOCK) {
			return colors.block[`${segment}-${block}`];
		}

		return getMudmapCdtColorByIndex(0);
	};

	const color = getColor(
		colorsBy === VisualizerColorBy.SEG_BLOCK ? VisualizerColorBy.BLOCK : colorsBy,
	);

	return (
		<BlockItemElement background={color}>
			<Text variant="caption2" color={hexToRgba(Color.textMain, 0.85)}>
				{itemId}
			</Text>
		</BlockItemElement>
	);
};
